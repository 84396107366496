import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="subscribe"
export default class extends Controller {
  static targets = ["radioButton"]
  connect() {
  }
  selectedPlan(event) {
    var action = document.getElementById('stripe-form').action;
    var url = new URL(action)
    var new_url = url.searchParams.set('plan_id', event.target.value);
    document.getElementById('stripe-form').action=url.href
  }
}
