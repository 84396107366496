import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="map-location"
export default class extends Controller {
  static targets = ["map", "coordinate"]
  connect() {
    if (window.google) {
      this.show();
    }
  }
  show() {
    const myLatLong = {
      lat: parseFloat(this.coordinateTarget.dataset.lat) || 0,
      lng: parseFloat(this.coordinateTarget.dataset.lng) || 0,
    }

    const map = new google.maps.Map(this.mapTarget, {
      zoom: 14,
      center: myLatLong,
      mapId: "50a5646732da1cd6",
    })
    const marker = new google.maps.Marker({
      position: {
        lat: myLatLong.lat,
        lng: myLatLong.lng

      },
      map: map,
      draggable: false,
      optimized: true,
      title: "Click to calculate the route from your location",
      url: `https://www.google.com/maps/place/${myLatLong.lat},${myLatLong.lng}`
    })

    google.maps.event.addListener(marker, 'click', function () {
      window.open(marker.url, '_blank')
    });
  }
}
