import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["source", "trigger"]

  copy(event) {
    event.preventDefault()
    navigator.clipboard.writeText(this.sourceTarget.value)

    this.sourceTarget.focus()
    var triggerElement = this.triggerTarget
    var textElement = this.messageSource;
    var initialHTML = triggerElement.innerHTML
    triggerElement.innerHTML = "<span style='color:bg-primary;'>" + "Copied " + "</span>"
    //    setTimeout(() => {
    //      triggerElement.innerHTML = initialHTML
    //      // unfocus
    //      this.sourceTarget.blur()
    //    }, 2000)
  }
}
