import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="confirmation-modal"
export default class extends Controller {
  static targets = ["modal"]

  connect() {
    this.initModal();
  }

  initModal() {
    let dialog = document.querySelector('dialog');
    dialog.addEventListener('cancel', (event) => {
      event.preventDefault();
    });

    this.modalTarget.showModal();
  }
}
