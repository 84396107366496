import {Controller} from "@hotwired/stimulus"

// Connects to data-controller="address"
export default class extends Controller {
  static targets = ["input",
    "latitude",
    "longitude",
    "address_1",
    "address_2",
    "state",
    "city",
    "country",
    "zip_code"]

  connect() {
    if (window.google) {
      this.initGoogle();
    }
  }

  initGoogle() {
    this.autocomplete = new google.maps.places.Autocomplete(this.inputTarget, {
      fields: ["geometry", "address_components"],
      types: ["address"],
    })
    this.autocomplete.addListener("place_changed", this.placeSelected.bind(this));
  }

  placeSelected() {
    const place = this.autocomplete.getPlace();
    console.log(place);

    if (!place.geometry) {
      return;
    }
    this.latitudeTarget.value = place.geometry.location.lat();
    this.longitudeTarget.value = place.geometry.location.lng();


    for (const component of place.address_components) {
      const componentType = component.types[0];

      switch (componentType) {

        case "street_number": case "premise":
          this.address_2Target.value = component.long_name;
          break;
        case "route":
          this.address_1Target.value = component.long_name;
          break;
        case "locality":
          this.cityTarget.value = component.long_name;
          break;
        case "postal_code":
          this.zip_codeTarget.value = component.long_name;
          break;
        case "administrative_area_level_1":
          this.stateTarget.value = component.long_name;
          break;
        case "country":
          this.countryTarget.value = component.short_name;
          break;
      }
    }
  }
}
