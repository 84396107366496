import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";

export default class extends Controller {
  static values = { guest: String, url: String };
  connect() {}

  change() {
    let url = this.urlValue;
    if (url.includes("guest_id") === false) {
      url = url + "?guest_id=" + this.guestValue;
    }
    post(`${url}`, { responseKind: "turbo-stream" });
  }
}
