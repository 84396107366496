import {Controller} from "@hotwired/stimulus"
// import confetti from 'canvas-confetti';

export default class extends Controller {

  randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }


  fire() {
    var duration = 15 * 1000;
    var animationEnd = Date.now() + duration;
    var defaults = {startVelocity: 30, spread: 360, ticks: 60, zIndex: 0};

    var interval = setInterval(() => {
      var timeLeft = animationEnd - Date.now();

      if (timeLeft <= 0) {
        return clearInterval(interval);
      }

      var particleCount = 50 * (timeLeft / duration);
      // since particles fall down, start a bit higher than random
      confetti({...defaults, particleCount, origin: {x: this.randomInRange(0.1, 0.3), y: Math.random() - 0.2}});
      confetti({...defaults, particleCount, origin: {x: this.randomInRange(0.7, 0.9), y: Math.random() - 0.2}});
    }, 250);
  }
}

