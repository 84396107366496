import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    var modals = Array.from(document.getElementsByClassName("modal"));
    modals.forEach(modal => {
      modal.querySelector("#modal-close").addEventListener("click", () => {
        modal.querySelector("#video-player").pause();
      })
    })
  }
}

