// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
import "@hotwired/turbo-rails"
import "./controllers"
import "./src/turbo_native/bridge"
import {StreamActions} from "@hotwired/turbo"

window.initMap = () => {
	console.log('initMap');
	const event = new Event("MapLoaded");
	event.initEvent("map-loaded", true, true);
	window.dispatchEvent(event);
}

Turbo.setConfirmMethod((message, element) => {
	let dialog = document.getElementById('turbo-confirm');
	dialog.querySelector('p').textContent = message;
	dialog.showModal();

	return new Promise((resolve, reject) => {
		dialog.addEventListener('close', () => {
			resolve(dialog.returnValue == "confirm");
		}, {once: true});
	})
})



StreamActions.console_log = function () {
	console.log(this.getAttribute("message"), this.templateContent, this.targetElements)
}

StreamActions.notification = function () {
	let title = this.getAttribute("title")
	Notification.requestPermission(function (status) {
		console.log(status)
		if (status == "granted") {
			debugger
			new Notification(title)
		}
	})
}




