import { Controller } from "@hotwired/stimulus"

const SECONDS = 59;
const MINUTES = 59;
const HOURS = 23;

export default class extends Controller {
  static values = {
    interval: { default: 1000, type: Number },
  };

  connect() {
    let seconds_counter = this.data.get("secondsValue");
    let minutes_counter = this.data.get("minutesValue");
    let hours_counter = this.data.get("hoursValue");
    let days_counter = this.data.get("daysValue");

    this._timer = setInterval(() => {
      if (
        (days_counter==0) &&
        (hours_counter == 0) &&
        (minutes_counter == 0) &&
        (seconds_counter == 0)
      ){
        this.stopTimer();

      } else {
        seconds_counter--;
        if (seconds_counter < 0) { 
          seconds_counter = SECONDS; 
          minutes_counter--;
        }
        if (minutes_counter < 0) {
          minutes_counter = MINUTES;
          hours_counter--;
        }
        if (hours_counter < 0) {
          hours_counter= HOURS;
          days_counter--;
        }
        if (days_counter < 0 ){
          days_counter = 0;
        }
      }
      document.getElementById(
        'counterElementSeconds'
      ).style.setProperty('--value', seconds_counter);

      document.getElementById(
        'counterElementMinutes'
      ).style.setProperty('--value', minutes_counter);

      document.getElementById(
        'counterElementHours'
      ).style.setProperty('--value', hours_counter);

      document.getElementById(
        'counterElementDays'
      ).style.setProperty('--value', days_counter);

    }, this.intervalValue);

  }

  stopTimer() {
    const timer = this._timer;
    if (!timer) return;
    clearInterval(timer);
  }

  disconnect() {
    // ensure we clean up so the timer is not running if the element gets removed
    this.stopTimer();
  }
}
