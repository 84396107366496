import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  submitButtonClasses = 'mt-4 btn btn-primary w-full';

  connect() {
    this.getSubmitButton().className += this.submitButtonClasses;
//    this.getFormLabelElement().classList.add('hidden');
    document.getElementById('card-element').className += ' mt-4';
    console.log(document.getElementById('card-element'))
  }
  getSubmitButton() {
    return document.querySelector('#stripe-form input[type="submit"]');
  }
  getFormLabelElement() {
    return document.querySelector('[for="card_element"]');
  }
}
