// Import and register all your controllers from the importmap under controllers/*

import { application } from "./application";

// Eager load all controllers defined in the import map under controllers/**/*_controller
//#import {eagerLoadControllersFrom} from "@hotwired/stimulus-loading"
//eagerLoadControllersFrom("controllers", application)

// Lazy load controllers as they appear in the DOM (remember not to preload controllers in import map!)
// import { lazyLoadControllersFrom } from "@hotwired/stimulus-loading"
// lazyLoadControllersFrom("controllers", application)

import HelloController from "./hello_controller";
import ThemeController from "./theme_controller";
import AddressController from "./address_controller";
import ConfirmationModalController from "./confirmation_modal_controller";
import ImagePreviewController from "./image_preview_controller";
import ItemsController from "./items_controller";
import MapLocationController from "./map_location_controller";
import ModalCloserController from "./modal_closer_controller";
import ModalVideoController from "./modal_video_controller";
import StripeFormController from "./stripe_form_controller";
import SubscribeController from "./subscribe_controller";
import TimerController from "./timer_controller";
import ConfettiController from "./confetti_controller";
import ClipboardController from "./clipboard_controller";
import TurboNative__SignOutController from "./turbo_native/sign_out_controller.js";
import FormController from "./bridge/form_controller";
import StripeController from "./stripe_controller";

application.register("hello", HelloController);
application.register("theme", ThemeController);
application.register("address", AddressController);
application.register("confirmation-modal", ConfirmationModalController);
application.register("image-preview", ImagePreviewController);
application.register("items", ItemsController);
application.register("map-location", MapLocationController);
application.register("modal-closer", ModalCloserController);
application.register("modal-video", ModalVideoController);
application.register("stripe-form", StripeFormController);
application.register("subscribe", SubscribeController);
application.register("timer", TimerController);
application.register("confetti", ConfettiController);
application.register("clipboard", ClipboardController);
application.register("turbo-native--sign-out", TurboNative__SignOutController);
application.register("bridge--form", FormController);
application.register("stripe", StripeController);
